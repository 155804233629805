@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Piedra&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Noto Sans TC", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-success {
  color: #28a745;
}

.text-primary {
  color: #ec6436;
}

.text-blue {
  color: #007bff;
}

.text-error {
  color: #dc3545;
}

.text-foodpanda {
  color: #cf0562;
}

.foodpanda-border {
  border: 2px solid #cf0562;
  border-radius: 20px;
  padding: 10px 15px 10px 15px;
}

